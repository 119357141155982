import React from "react";
import App from "../components/App";

import { graphql, PageProps, useStaticQuery } from "gatsby";
import { GetPartnersSectionQuery } from "../generated/graphql";
import PageBuilder from "../components/PageBuilder";
import SEO from "../components/SEO";

const GET_PARTNERS_SECTION = graphql`
  query GetPartnersSection {
    allContentfulPage(filter: { page: { eq: "Partners" } }) {
      edges {
        node {
          section {
            ... on ContentfulPartners {
              backgroundColor
              images {
                gatsbyImageData
              }
              partnersTitle: title
            }
            ... on ContentfulHero {
              heroDescription: description
              heroHeading: heading
            }
          }
          title
          description
          page
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

const Partners = ({ location }: PageProps) => {
  const partnerSections =
    useStaticQuery<GetPartnersSectionQuery>(GET_PARTNERS_SECTION);
  const {
    section: data,
    image,
    title,
    description
  } = partnerSections?.allContentfulPage?.edges?.[0]?.node;
  return (
    <App>
      <SEO
        title={title}
        description={description}
        url={location.href}
        image={image?.file?.url}
      />
      <PageBuilder data={data} />
    </App>
  );
};

export default Partners;
